<template>
  <div class="driver">
    <!-- 主体内容 -->
    <div class="driver_cont">
      <div
        class="pattern cont"
        v-class="
          'pattern cont animate__animated animate__fadeInup animate__block'
        "
      >
        <div class="second">
          <span class="size36">企业责任</span>
          <p>Responsibility</p>
          <div v-for="(item,index) in menu" :key="index">
            <div @click="btn(index)">
              <div class="img">
                <img class="simg" :src="item.src" alt="" />
              </div>
              <div class="second_part">
                <div class="part">
                  <span>{{item.title}}</span>
                  <div class="line1"></div>
                  <p>
                    {{item.text}}
                  </p>
                </div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <foot :animate="false"></foot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu:[{
        title:"免费服务 致敬抗疫一线医疗工作者",
        text:"新冠疫情爆发后，为向广大抗疫一线工作者致敬，公司实施合肥市抗疫一线工作者免费预约出行服务政策，2020年2月11日起，合肥市一线的医疗工作者、政府机构工作人员、社区工作者均可申请并享受免费出行服务。活动圆满完成3名医务工作者和1名社区工作者的乘车服务工作，共计免费服务57单；其中2名医务工作者和1名社区工作者拍摄相关致谢视频对我公司提供的公益活动表示感谢。",
        // 在data中引入图片的时候，必须使用require
        src:require('../../assets/images/1.png')
      },{
        title:"共克时艰 捐款助力疫情防控",
        text:"“一方有难，八方支援”，面对突如其来的新冠疫情，和行公司高度重视，向广大党员干部职工发出为疫区捐款奉献爱心的倡议，公司领导率先垂范，带头捐款，干部职工积极响应，参与到这次募捐活动中来，大家通过银行转账、微信捐款以及现金捐赠等形式奉献爱心，纷纷为抗击疫情贡献一份绵薄之力，共计93名干部职工捐款，捐款金额20000元。",
        src:require('../../assets/images/2.png')
      },{
        title:"人车防护 力保司乘安全",
        text:"新冠疫情爆发后，公司为所有运营网约车、出租车、租赁车辆提供免费消毒服务和医用口罩，其中消毒服务对全市所有社会车辆同步免费提供，全市目前共设置3个消毒站点（包河区南二环和徽州大道交叉口西南角和瑞出租车院内、长丰县水湖镇长寿路177号长丰县百帮就业园、肥西县繁华大道与万佛山路交口合肥和瑞出租车有限公司），目前累计消毒车辆近5000辆。公司对所有营运车辆驾驶员体温、近期接触人员和乘客情况进行每日登记，使疫情防控工作有迹可循、有记录可查。",
        src:require('../../assets/images/3.png')
      },{
        title:"弘扬正气 好人好事不断",
        text:"2021期间，公司共涌现各类好人好事1189起，为乘客挽回经济损失约43万元。其中被省、市级以上媒体报道的14起，收到乘客锦旗1面，收到乘客表扬信或表扬电话14起。",
        src:require('../../assets/images/4.png')
      }
      ]
    };
  },
  methods: {
    btn(index) {
      switch (index) {
        case 0:
          this.$router.push({ name: "slect11" });
          break;
        case 1:
          this.$router.push({ name: "slect22" });
          break;
        case 2:
          this.$router.push({ name: "slect33" });
          break;
        case 3:
          this.$router.push({ name: "slect44" });
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.size36 {
  font-size: 0.52rem;
}
p {
  margin-left: 1px;
  font-size: 0.26rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.animate__block {
  visibility: visible !important;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0.2s;
}
.animate__fadeInup {
  -webkit-animation-name: FadeInup;
  animation-name: FadeInup;
}
.animate__animated {
  --animate-duration: 0.5s;
}
.animate__animated.animate__fadeIn {
  --animate-duration: 0.5s;
}
.driver {
  position: relative;
}
.driver_cont {
  margin-bottom: 0.5rem;
}
.cont {
  color: #2d2d39;
  margin-top: 0.9rem;
  visibility: hidden;
}
.cont .second {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
}
.pattern .second {
  padding: 0 0.48rem;
}
.pattern .second span {
  font-weight: bold;
  margin: 0.25rem 0 0.07rem;
}

.pattern .second .second_part {
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 0.05rem;
}
.pattern .second .second_part .part span {
  font-size: 0.3rem;
  margin: 0.13rem auto 0.13rem;
}
.pattern .second .second_part .part p {
  font-size: 0.26rem;
  color: #848494;
  margin: 0 0 0.47rem;
}
@keyframes FadeInup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30%);
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.img {
  // width: 87.2%;
  width: 98%;
  margin: 8% auto 0;
}
.simg {
  width: 100%;
  height: 100%;
}
.line {
  width: 98%;
  height: 1px;
  margin: 0 auto;
  background-color: #e3e3e9;
}
.line1 {
  width: 20%;
  height: 1px;
  margin: 7px 0 10px;
  background-color: #e3e3e9;
}
</style>